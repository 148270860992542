import { faCalendar, faCreditCard, faLocationDot, faPeopleLine } from '@fortawesome/free-solid-svg-icons';
import { graphql, navigate } from 'gatsby';
import React from 'react';
import BulletPoint from '../components/about/bullet';
import Layout from '../components/layout';
import PageSection from '../components/page-section';
import Seo from '../components/seo';
import ClientOnly from '../components/client-only';
import ToursList from '../components/tour-calendar-list/list/ToursList';

import { useDispatch } from "react-redux"
import { Dispatch } from "../store/store"
import { useEffect } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import ToursCalendarList from '../components/tour-calendar-list/tour-calendar-list';
import ToursDaysList from '../components/tour-calendar-list/tours-days-list';
import { DateTime } from "luxon"

const Tours2023TicketsAboutPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch<Dispatch>();
    useEffect(() => {
        dispatch.toursCalendar.initialize({ translate: t, initDate: DateTime.fromISO("2023-09-10"), filterByProject: "REP_23", });
    }, [])

    return (
        <Layout>
            <Seo title={"Bezpłatne rejsy edukacyjno-przyrodnicze 2023"} />
            <PageSection>
                <h1><strong>Bezpłatne rejsy</strong><br /> edukacyjno-przyrodnicze 2023</h1>
                <div className="py-3"></div>
                <h3>Rejsy w ramach World CleanUpDay (16.09) oraz w ostatniego weekendu działań w ramach sezonu letniego nad Wisłą (23.09)</h3>
                <div className="py-3"></div>
                <div className="pt-3">
                    <a href="#booking-section" type="button" className="btn btn-secondary btn-lg mb-3 mb-sm-none">Rezerwuj</a>
                    <span className="ps-3"></span>
                    <a id="read-more-tickets" href="#more-about-tickets-section" role="button" className="btn btn-outline-light btn-lg mb-3 mb-sm-none">Czytaj więcej</a>
                </div>
            </PageSection>
            <PageSection className='bg-white text-black' id="more-about-tickets-section" data-bs-spy="scroll" data-bs-target="#read-more-tickets">
                <div className="container-fluid px-4 px-md-0 py-2">
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col-sm-6">
                                    <BulletPoint icon={faLocationDot} title={"Wymagania"} >
                                        <span>Należy przybyć <strong>najpóźniej 15 min przed terminem rozpoczęcia rejsu</strong>.</span>
                                    </BulletPoint>
                                </div>
                                <div className="col-sm-6">
                                    <BulletPoint icon={faCalendar} title={"Otwarcie rezerwacji"} >
                                        <span>Otwarcie zapisów na najbliższe rejsy odbędzie się ....</span>
                                    </BulletPoint>
                                </div>
                                <div className="col-sm-6">
                                    <BulletPoint icon={faPeopleLine} title={"Ograniczenia"} >
                                        <span>Jedna osoba może złożyć rezerwację na grupę <strong>maks. 2 osób na 1 rejs</strong>.</span>
                                    </BulletPoint>
                                </div>
                                <div className="col-sm-6">
                                    <BulletPoint icon={faCreditCard} title={"Kaucja zwrotna"} >
                                        <span>Za każdego uczestnika rejsów pobieramy kaucję zwrotną w wysokości <br /><strong>20zł /os</strong>.</span>
                                    </BulletPoint>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageSection>
            <PageSection id="booking-section" data-bs-spy="scroll" data-bs-target="#booking-section">
                <h1 className="pb-5">Zapisy online</h1>
                <div className="py-2" ></div>
                <p>Poniżej znajdują się rejsy na które można zarezerwować miejsca.</p>
                <p>Podczas rejsów o 10:00 i 11:00 będzie można włączyć się w akcję sprzątania Wisły.</p>
            </PageSection>
            <PageSection id="toursCalendarSection" className="bg-white text-black position-relative" style={{ minHeight: 400 }}>
                <ClientOnly>
                    <ToursCalendarList />
                </ClientOnly>
            </PageSection>
        </Layout>
    );
}

export default Tours2023TicketsAboutPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns:{in: ["navigation","footbar" ]},language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;